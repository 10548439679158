import FPAlert from "lib/fp_alert";
// import $ from "jquery";

export function initApp() {
    window.FPAlert = FPAlert;

    document.addEventListener("turbolinks:load", event => {
        console.log("loading: base...");

        $("#main-menu .dropdown").dropdown();

        if ($.rails) {
            $.rails.allowAction = function (link) {
                if (!link.attr("data-confirm")) {
                    return true;
                }

                $.rails.showConfirmDialog(link);
                return false;
            };

            $.rails.confirm = function (link) {
                link.removeAttr("data-confirm");
                return link.trigger("click.rails");
            };

            $.rails.showConfirmDialog = function (link) {
                const title = link.data("title");
                const message = link.data("confirm");
                const icon = link.data("icon");

                const html = `
                        <div class="ui basic modal">
                          <div class="ui icon header">
                            <i class="${icon} icon"></i>
                            ${title}
                          </div>
                          <div class="content">
                            <p>${message}</p>
                          </div>
                          <div class="actions">
                            <div class="ui red basic cancel inverted button">
                              <i class="remove icon"></i>
                              No
                            </div>
                            <div class="ui green ok inverted button">
                              <i class="checkmark icon"></i>
                              Yes
                            </div>
                          </div>
                        </div>`;

                return $(html)
                    .modal({
                        onApprove() {
                            return $.rails.confirm(link);
                        }
                    })
                    .modal("show");
            };
        }

        if (typeof ga === "function") {
            ga("set", "location", event.target.URL);
            return ga("send", "pageview");
        }
    });
}
