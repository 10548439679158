// Turbolinks
import Turbolinks from "turbolinks";

// Rails
import { initApp } from "core/core";

// StyleSheets
import "core/core.scss";

// Action Cable
import ActionCable from "actioncable";

Turbolinks.start();
initApp();

export const cable = ActionCable.createConsumer();
