import Noty from "noty";
import "./noty.css";

const DEFAULT_TIMEOUT = 5000;

export default class FPAlert {
    /**
     * Displays an alert with the given text and optional type. Alert is generic gray without type
     * @param {string} text
     * @param {string} [type='alert']
     * @param {boolean,int} [timeout=5000] - Time in milliseconds or false for no timeout
     */
    static alert = (text, type = "alert", timeout = DEFAULT_TIMEOUT) => {
        new Noty({
            closeWith: ["click", "button"],
            text,
            type,
            theme: "semanticui",
            timeout
        }).show();
    };

    /**
     * Displays an untimed error alert with the messages given
     * @param {array} messages - Array of strings
     */
    static errors = messages =>
        FPAlert.error(
            messages.reduce((sum, message, index) => {
                const li = `${sum}<li>${message}</li>`;

                if (index === messages.length - 1) {
                    return `${li}</ul>`;
                }

                return li;
            }, "<h4>Please correct the following fields:</h4><ul>"),
            false
        );

    /**
     * Handles an axios error and displays an alert
     * @param {Error} error - An axios error
     */
    static handleError = error => {
        const genericError = "Something went wrong, please try again later.";
        const unauthorized = "You are not authorized to perform this action.";

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const { data, status } = error.response;
            switch (status) {
                case 422:
                    FPAlert.error((data && data.message) || genericError);
                    break;
                case 401:
                    FPAlert.error(unauthorized);
                    break;
                case 402:
                    FPAlert.error(unauthorized);
                    break;
                case 404:
                    FPAlert.error("The resource you requested does not exist.");
                    break;
                default:
                    FPAlert.error(genericError);
                    break;
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            FPAlert.error(genericError);
        } else {
            // Something happened in setting up the request that triggered an Error
            FPAlert.error(genericError);
        }
    };

    /**
     * Displays an error alert with the given text
     * @param {string} text
     * @param {boolean,int} [timeout=5000] - Time in milliseconds or false for no timeout
     */
    static error = (text, timeout = DEFAULT_TIMEOUT) => FPAlert.alert(text, "error", timeout);

    /**
     * Displays a success alert with the given text
     * @param {string} text
     * @param {boolean,int} [timeout=5000] - Time in milliseconds or false for no timeout
     */
    static success = (text, timeout = DEFAULT_TIMEOUT) => FPAlert.alert(text, "success", timeout);

    /**
     * Displays a warning alert with the given text
     * @param {string} text
     * @param {boolean,int} [timeout=5000] - Time in milliseconds or false for no timeout
     */
    static warning = (text, timeout = DEFAULT_TIMEOUT) => FPAlert.alert(text, "warning", timeout);
}
